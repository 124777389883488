import lt from './lang/lt/translations.lt'
import en from './lang/en/translations.en'
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import i18next from 'i18next'

// export class LanguageService {
//   private currentLanguage: LANGUAGE = LANGUAGE.LT
//
//   setLang(language: LANGUAGE) {
//     this.currentLanguage = language
//   }
//
//   get lang() {
//     return this.currentLanguage
//   }
// }

// export let languageService = new LanguageService()

export enum LANGUAGE {
  LT = 'lt',
  EN = 'en',
}

export const langResources: {
  [key in LANGUAGE]: { translation: typeof lt | typeof en }
} = {
  [LANGUAGE.LT]: { translation: lt },
  [LANGUAGE.EN]: { translation: en },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: langResources,
    lng: 'lt', // language to use, more information here:
               // https://www.i18next.com/overview/configuration-options#languages-namespaces-resources

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

export const tran = (key) => {
  return i18next.t(key) ?? key
}
