import 'typeface-open-sans'
import './core/i18n/i18n'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './core/navigation/routes/App'

const rootElement = document.getElementById('root')

ReactDOM.render(<App />, rootElement)
