import { tran } from '../../core/i18n/i18n'

export let NAV = {
  ABOUT: { path: '/', langKey: 'aboutUsScreenTitle', id: 'about-id' },
  HOME: { path: '/about', langKey: 'aboutUsScreenTitle', id: 'about-id-1' },
  GALLERY: { path: '/gallery', langKey: 'galleryScreenTitle', id: 'gallery-id' },
  SCHOOLS: { path: '/schools', langKey: 'schoolsScreenTitle', id: 'schools-id' },
  EVENTS: { path: '/events', langKey: 'eventsScreenTitle', id: 'events-id' },
  PRICES: { path: '/prices', langKey: 'pricesScreenTitle', id: 'prices-id' },
  CONTACTS: { path: '/contacts', langKey: 'contactsScreenTitle', id: 'contacts-id' },
}
