import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { NAV } from '../../../features/navigation/paths'
import Home from '../../../features/home/screens/Home'
import About from '../../../features/about/screens/About'
import Gallery from '../../../features/gallery/screens/Gallery'
import Schools from '../../../features/schools/screens/Schools'
import Prices from '../../../features/prices/screens/Prices'
import Contact from '../../../features/contacts/screens/Contact'
import Login from '../../../features/admin/screens/Login'
import Admin from '../../../features/admin/screens/Admin'
import React, { createContext, useContext, useState } from 'react'
import MainMenu from '../../../features/navigation/components/MainMenu'
import styled from 'styled-components'
import { Color } from '../../styles/colors/color'
import Events from '../../../features/events/screens/Events'

const AppWrapper = styled.div`
  min-height: 100vh;
  //flex-grow: 1;
  background-color: ${Color.BLACK};
  //min-width: 100%;

  //display: flex;
  //overflow: hidden;
  //overflow-style: marquee-block;
  //position: absolute;
  //background: linear-gradient(to bottom right, #55566a, #282834);
  //align-items: center;
  //justify-content: center;
  //align-self: center;
`

const AppImg = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  //flex-grow: 1;
  // pt: '10vh',
  // width: '100vh',
  // maxHeight: '100%',
  // overflow: 'auto',
  background-image: url(${require('../../../assets/images/cyber_art2.PNG')});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  //background-size: 800px;
  // background-color: ${Color.BLACK};
  //width: 100%;
  // flexGrow: 1,
  display: flex;
  // flexWrap: 'wrap',
  // flexDirection: 'row',
  //position: fixed;
  zindex: -99;

  // backgroundSize: 'cover',
  //top: 0;
  // paddingBottom: { xs: '0px', md: '50px' },
  // paddingTop: '10vh',
  background-color: rgba(0, 0, 0, 1);
  // background: 'blue',
`

const TransparentBackground = styled.div`
  height: 100%;
  width: 100%;
  //flex-grow: 1;
  // pt: '10vh',
  // width: '100vh',
  // maxHeight: '100%',
  // overflow: 'auto',
  //background-size: 800px;
  // background-color: ${Color.BLACK};
  //width: 100%;
  // flexGrow: 1,
  // display: 'flex',
  // flexWrap: 'wrap',
  // flexDirection: 'row',
  //position: absolute;

  // backgroundSize: 'cover',
  //top: 0;
  // paddingBottom: { xs: '0px', md: '50px' },
  // paddingTop: '10vh',
  background-color: rgba(0, 0, 0, 0.6);
  // background: 'blue',
`

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true
    setTimeout(cb, 100) // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false
    setTimeout(cb, 100)
  },
}

function useProvideAuth() {
  const [user, setUser] = useState('')

  const signin = cb => {
    return fakeAuth.signin(() => {
      setUser('user')
      cb()
    })
  }

  const signout = cb => {
    return fakeAuth.signout(() => {
      setUser('')
      cb()
    })
  }

  return {
    user,
    signin,
    signout,
  }
}

const authContext = createContext({ user: '' })

function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

function useAuth(): { user: string } {
  return useContext(authContext)
}

const PrivateRoute = ({ children, ...rest }) => {
  const token = localStorage.getItem('jwt')
  return token ? children : <Login />
}

export const App = () => {
  return (
    <ProvideAuth>
      <BrowserRouter>
        {/* <MainMenu /> */}
        <Routes>
          <Route path={NAV.ABOUT.path} element={<About />} />
        </Routes>
        {/* <Route path={NAV.ABOUT.path} element={<About />} />
          <Route path={NAV.HOME.path} element={<About />} />
          <Route
            path={NAV.EVENTS.path}
            element={
              <AppImg>
                <TransparentBackground>
                  <Events />
                </TransparentBackground>
              </AppImg>
            }
          />
          <Route
            path={NAV.GALLERY.path}
            element={
              <AppImg>
                <TransparentBackground>
                  <Gallery />
                </TransparentBackground>
              </AppImg>
            }
          />
          <Route path={NAV.SCHOOLS.path} element={<Schools />} />
          <Route
            path={NAV.PRICES.path}
            element={
              <AppImg>
                <TransparentBackground>
                  <Prices />
                </TransparentBackground>
              </AppImg>
            }
          />
          <Route
            path={NAV.CONTACTS.path}
            element={
              <AppImg>
                <TransparentBackground>
                  <Contact />
                </TransparentBackground>
              </AppImg>
            }
          />
          <Route path={'/login'} element={<Login />} />
          <Route
            path={'/admin'}
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
        </Routes> */}
      </BrowserRouter>
    </ProvideAuth>
  )
}
