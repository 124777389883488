export default {
  test: 'testas',
  homeScreenTitle: 'Pagrindinis',
  aboutUsScreenTitle: 'Apie mus',
  galleryScreenTitle: 'Galerija',
  pricesScreenTitle: 'Kainos',
  contactsScreenTitle: 'Kontaktai',
  schoolsScreenTitle: 'Mokykla',
  eventsScreenTitle: 'Renginiai',
  workingHours: 'Darbo laikas',
  I: 'I',
  IV: 'IV',
  V: 'V',
  VI: 'VI',
  VII: 'VII',
  number12: '12',
  number14: '14',
  number16: '16',
  number15: '15',
  number17: '17',
  number20: '20',
  number23: '23',
  number02: '02',
  number00: '00',
  number40: '40',
  number50: '50',
  number45: '45',
  number18: '18',
  number2022: '2022',
  addressLocation: 'Antakalnio g. 87, LT-10215, Vilnius',
  phoneNumber: '+37064810967',
  cyberPoolInfoEmail: 'info@cyberpool.lt',
  playPrices: 'Žaidimo kainos',
  tableCostPerHour: 'Žaidimo stalo nuoma valandai',
  tableLPFDiscount: 'LFP pulo sportininkams pagal 2022m. LFP pulo vyrų, moterų, veteranų 45+ reitingus 40% nuolaida',
  tableCostDiscountForDisabled: 'Neįgaliesiems 50% darbo dienomis',
  tableExtraCostForICA: 'ICA treniruočių sistema +5€ prie stalo nuomos',
  tableSoldierDiscountTitle: 'Karininkams 40% nuolaida',
  tableCostDiscountForStudents: 'Studentams 20% nuolaida',
  eur: '€',
  note: '*',
  aboutUsCyberIntroInfo:
    'Pirmasis ir vienintelis interaktyvus pulo klubas ne tik Vilniuje, bet ir Baltijos šalyse CYBER POOL kviečia smagiai praleisti laiką ir patobulinti savo įgūdžius. Klube lankytojus pasitinka išskirtinis interjeras, jauki atmosfera ir profesionaliam žaidimui pritaikyti stalai. Kviečiame užsukti ir atrasti įtraukiantį pulo pasaulį!',
  aboutUsCyberDesignInfo:
    'Kas yra pulas? Tai biliardo žaidimas turintis daugybę variacijų ir įtraukiantis tiek mažus, tiek didelius. Pulo žaidimų variacijų yra daug, o kaip populiariausius žaidimus galima išskirti aštuoniukę, devyniukę ir 14+1. Pulas žaidžiamas prie biliardo stalo su šešiomis skylėmis, o pagrindinis žaidimo tikslas – tikslus smūgis, leidžiantis įmušti kamuolius.',
  aboutUsCyberWhatWeDoHeader:
    'Kas gali žaisti pulą? Pulas yra labai universalus, todėl įtraukia ne tik mėgėjus ar profesionalus, bet ir naujokus. Pulą žaisti gali visi: maži ir dideli, jauni ir seni. Tai sportas, kuris tinka visiems, o svarbiausia – dovanoja daug geros nuotaikos! Todėl ir sukūrėme CYBER POOL – vietą, kurioje kiekvienas gali smagiai leisti laiką ir gerinti savo įgūdžius.',
  aboutUsCyberWhatWeDoDescription:
    'Patobulinkite savo įgūdžius! Mes suprantame, kad pratimai ir pamokos reikalauja daug laiko gali sumažinti motyvaciją. Tik ne CYBER POOL! Mūsų klube įdiegta interaktyvi ICA mokymų sistema, kuri suteikia galimybę smagiai tobulinti savo įgūdžius. Sistema leidžia kartoti smūgius ir pataria, kaip juos geriau atlikti, todėl galite treniruotis teisingai ir iš karto pastebėsite savo pažangą! Treniruočių režimas turi skirtingus sunkumo lygius ir daugybę parinkčių, todėl tinka kiekvienam. Tai tarsi profesionalus treneris, su kuriuo pasieksite puikių rezultatų!',
  aboutUsCyberWhatWeDoDescription2:
    'Biliardas – tai puiki terapija norint pamiršti dienos darbus ir rūpesčius. Užsukite ir smagiai praleiskite laiką su šeima ir draugais!',
  schoolsBeginningHeader: 'Biliardo mokyklos pradžia 2022',
  schoolsInnerHeader: 'Kartu pasieksime tikslų! 💯',
  schoolsTrainingWithICA: 'Treniruosimės su interaktyvia ICA treniruočių sistema',
  schoolsInsideMatches: 'Vidiniai turnyrai',
  schoolsCertificatesAreGiven: 'Baigimo proga išduodami sertifikatai',
  schoolsInviteToGroupPoolTraining: '👋 Kviečiam į  grupines pulo treniruotes',
  schoolsWhenGroupPoolTrainingTakePlaceTitle: 'Kada jos vyksta?',
  schoolsWhenGroupPoolTrainingTakePlaceDescription: 'Antradienį, Ketvirtdienį 15.00 - 17.00',
  schoolsWhereGroupPoolTrainingTakePlaceTitle: 'Kurioje vietoje?',
  schoolsWhereGroupPoolTrainingTakePlaceDescription: 'Antakalnio 87, Vilnius',
  schoolsCyberPoolClub: 'CYBER POOL  Biliardo klubas',
  schoolsCyberPoolTrainer: 'Vilius Schulte-Ebbert snukerio/pulo žaidėjas ir sertifikuotas EBSA treneris.',
  schoolsAboutCyberPoolTrainer: 'Trenerio patirtis 8 metai nuo 2014-2022. ⬇️',
  schoolsAboutCyberPoolTrainer1: '♦️ 900 + studentai iš 46 šalių nuo 7 metų iki 75. Tai pat su neįgaliaisiais.',
  schoolsAboutCyberPoolTrainer2: '♦️ Sukurti 3 online snukerio pulo kursai.',
  schoolsAboutCyberPoolTrainer3: '♦️ Patirtis dirbant su snukerio, pulo, rusiško biliardo žaidėjais.',
  schoolsRegisterForTraining: 'Kur registruotis dėl treniruočių?',
  schoolsTrainerEmail: 'Schulte.ebbert@gmail.com',
  schoolsTrainerOr: 'arba',
  schoolsTrainerPhone: '+37062089254',
  schoolsTrainerFullName: ' (treneris Vilius Schulte-Ebbert)',
  schoolsSeeYouSoon: 'Iki greito!',
  username: 'Vartotojo vardas',
  password: 'Slaptažodis',
  login: 'Prisijungti',
  eventStart: 'Pradžia: ',
  eventEnd: 'Pabaiga: ',
  eventLocation: 'Vieta: ',
  news: 'Renginiai',
  title: 'Antraštė',
  description: 'Aprašymas',
  imageStyle: 'Paveikslėlio stilius',
  imageContainerStyle: 'Paveikslėlio konteinerio stilius',
  imagePosition: 'Paveikslėlio pozicija',
  imgPositionBottom: 'Apačioje',
  imgPositionTop: 'Viršuje',
  imgPositionLeft: 'Kairėje',
  imgPositionRight: 'Dešinėje',
  type: 'Tipas',
  typeChallenge: 'Įššūkis',
  typeNews: 'Naujienos',
  typeInfo: 'Informacija',
  typeEvent: 'Įvykis',
  submit: 'Patvirtinti',
  englishLanguageSwitch: 'Anglų kalba',
  remove: 'Pašalinti',
  descriptionLink: 'Nuoroda',
  readMore: 'Skaityti plačiau',
  readLess: 'Skaityti mažiau',
}
