export enum Color {
  RED = 'red',
  DARK_GREY900 = '#161616',
  DARK_GREY600 = '#313131',
  WHITE = '#ffffff',
  BLACK = '#000000',
  TRANSPARENT = 'transparent',
  BLACK_OP80 = 'rgba(255, 255, 255, 0.8)',
  BLACK_OP70 = 'rgba(255, 255, 255, 0.7)',
  BLACK_OP40 = 'rgba(255, 255, 255, 0.4)',
  BLACK_OP30 = 'rgba(255, 255, 255, 0.3)',
  BLACK_OP15 = 'rgba(255, 255, 255, 0.15)',
  BLACK_OP10 = 'rgba(255, 255, 255, 0.1)',
}
