import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
  Box, Button,
  Container,
  CssBaseline,
  Grid,
  Icon, IconButton,
  Input, InputAdornment,
  InputLabel,
  Link,
  Paper,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { tran } from '../../../core/i18n/i18n'
import { Color } from '../../../core/styles/colors/color'
import { theme } from '../../../core/styles/theme/typography/font'
import {
  AccessTimeFilledOutlined as AccessTimeFilledOutlinedIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  LocalPhoneOutlined as LocalPhoneOutlinedIcon,
  FacebookOutlined as FacebookOutlinedIcon,
} from '@mui/icons-material'
import Image from '../../../core/components/Image'
import axios from 'axios'
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import { API, API_BASE_URL } from '../../../core/constants/api'

export const styles = {
  header: {

    minHeight: '100vh',
    // pt: '10vh',
    // width: '100vh',
    // maxHeight: '100%',
    // overflow: 'auto',

    width: '100%',
    // flexGrow: 1,
    // display: 'flex',
    // flexWrap: 'wrap',
    // flexDirection: 'row',
    // position: 'absolute',

    // backgroundSize: 'cover',
    // top: 0,
    paddingBottom: { xs: '0px', md: '50px' },
    // paddingTop: '10vh',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    // background: 'blue',
  },

  content: {
    // height: { md: '100vh' },
    // width: '100%',
    flexGrow: 1,

    display: 'flex',
    // backgroundColor: 'rgba(0, 0, 0, 0.6)',
    paddingTop: 5,
    // background: Color.BLACK,
  },
  innerContent: {
    // height: '100%',
    // width: '100%',
    backgroundColor: 'rgba(211,211,211, 0.1)',
    // backgroundColor: 'red',
    borderRadius: 6,
    // marginTop: '15%',
    p: 2,
    border: '1px solid',
    flexDirection: 'column',
    borderColor: Color.BLACK_OP30,
    // flexDirection: 'column',
    // height: '250px',
    width: { xs: '250px', md: '300px' },
    // minWidth: { xs: '260px', md: '350px' },
    boxShadow: '0 14px 28px rgba(0,0,0,0.66), 10px 10px 10px rgba(0,0,0,0.33)',
    // justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    // position: 'relative',
    // top: 30,
    // left: 0,
    // right: 0,
    // bottom: -30,
  },
  galleryInnerContent: {
    // backgroundColor: 'rgba(211,211,211, 0)',
    backgroundColor: Color.DARK_GREY900,
    borderRadius: 6,
    // marginTop: '15%',
    p: '3%',
    marginTop: 5,
    border: '1px solid',
    borderColor: Color.BLACK_OP30,
    // backgroundColor: 'red',
    width: '95%',
    height: '80%',
    // flexDirection: 'column',
    // height: { xs: '350px', md: '430px' },
    // width: { xs: '95%', md: '95%' },
    // minWidth: { xs: '95%', md: '95%' },
    boxShadow: '0 14px 28px rgba(0,0,0,0.66), 10px 10px 10px rgba(0,0,0,0.33)',
  },
  contactHeaderLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    mb: 2,
  },
  contactIconStyling: {
    marginRight: 2,
    color: Color.WHITE,
    width: '100%',
    height: '50px',
    // margin: 'auto',
    // backgroundColor: 'yellow'
  },
  contactIconStylingCursor: {
    marginRight: 2,
    color: Color.WHITE,
    width: '100%',
    height: '50px',
    cursor: 'pointer',
  },
  workingDaysMainBox: { display: 'flex', pt: 1, pb: 1 },
  workingHoursMainBox: { display: 'flex', flexDirection: 'column' },
  priceWrapperBox: { mt: 1, width: '100%', flexDirection: 'row', justifyContent: 'center' },
  flex: { display: 'flex', width: '22%' },
  gapBoxXs: {
    mb: 3,
  },
  mainInnerContainer: {
    // flexGrow: 1,
    // display: 'flex',
    flexDirection: { xs: 'column', md: 'column' }, //related to XS
    // width: '100% ',
    // backgroundColor: 'black',
    paddingBottom: '50px',
    // height: '100%',
  },
  descriptionAboutPricesBox: {
    display: 'flex',
    // backgroundColor: 'blue',
    width: '100%',
    mt: 2,
    justifyContent: 'center',
  },
  descriptionAboutPricesInnerBoxWrapper: {
    minWidth: { xs: '220px', md: '350px' },
    width: { xs: '66%', md: '300px' },
    // background: 'red',
    maxWidth: '100%',
  },
  descriptionAboutUs: {
    // display: 'flex',
    // backgroundColor: 'blue',
    flexDirection: 'row',
    width: '100%',
  },
}

export const Login = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [state, setState] = useState<{ error: boolean; authorized: boolean; username: string; password: string; passwordVisible: boolean }>({
    username: '',
    password: '',
    error: false,
    passwordVisible: false,
    authorized: false,
  });

  const onPasswordVisibilityChangeClick = (): void => {
    setState({ ...state, passwordVisible: !state.passwordVisible })
  }

  const onTextChanged = (textPropName: 'username' | 'password') => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [textPropName]: event.target.value })
  }

  const onLoginClick = () => {
    axios.post(`${API_BASE_URL}${API.LOGIN()}`, {
      username: state.username,
      password: state.password,
    }).then((res) => {
      if (res.data.token) {
        localStorage.setItem('jwt', res.data.token)
        setState({ ...state, authorized: true, error: false })
        navigate('/admin', { replace: true })
      } else {
        setState({ ...state, error: true })
      }
    }).catch(err => {
      setState({ ...state, error: true })
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container sx={styles.content}>
        <Container sx={[styles.innerContent]}>
          <Box sx={{ flexDirection: 'row', mb: 1 }}>
            <Typography variant={'subtitle1'} color={Color.WHITE}>
              {t('username')}
            </Typography >
            <Input
              disableUnderline={true}
              value={state.username}
              onChange={onTextChanged('username')}
              inputProps={{
                style: {
                  paddingLeft: 10,
                  paddingRight: 5,
                },
              }}
              sx={{
                // display: 'flex',
                color: Color.WHITE,
                border: '1px solid',
                borderColor: state.error ? Color.RED : Color.WHITE,
                ph: 1,
                borderRadius: 1.5,
              }}
            />

          </Box >
          <Box sx={{ flexDirection: 'row' }}>
            <Typography variant={'subtitle1'} color={Color.WHITE}>
              {t('password')}
            </Typography >
            <Input
              disableUnderline={true}
              onChange={onTextChanged('password')}
              type={state.passwordVisible ? 'text' : 'password'}
              value={state.password}
              error={state.error}
              inputProps={{
                style: {
                  paddingLeft: 10,
                  paddingRight: 5,
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ pr: 2 }}
                    aria-label="toggle password visibility"
                    onClick={onPasswordVisibilityChangeClick}
                    edge="end"
                  >
                    {state.passwordVisible ? <VisibilityOffIcon color={'primary'}/> :
                      <VisibilityIcon color={'primary'}/>}
                  </IconButton >
                </InputAdornment >
              }
              sx={{
                color: Color.WHITE,
                border: '1px solid',
                borderColor: state.error ? Color.RED : Color.WHITE,
                ph: 2,
                borderRadius: 1.5,
              }}
            />

          </Box >
          <Button onClick={onLoginClick} sx={{ mt: 2 }} variant="contained">{t('login')}</Button >
        </Container >
      </Container >
    </ThemeProvider >
  )
}

export default Login
