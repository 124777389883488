import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Container, CssBaseline, Grid, Icon, Link, Paper, ThemeProvider, Typography } from '@mui/material'
import { tran } from '../../../core/i18n/i18n'
import { Color } from '../../../core/styles/colors/color'
import { theme } from '../../../core/styles/theme/typography/font'
import {
  AccessTimeFilledOutlined as AccessTimeFilledOutlinedIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  LocalPhoneOutlined as LocalPhoneOutlinedIcon,
  FacebookOutlined as FacebookOutlinedIcon,
  MailOutlined as MailOutlinedIcon,
} from '@mui/icons-material'
import Image from '../../../core/components/Image'
import axios from 'axios'
import { API, API_BASE_URL } from '../../../core/constants/api'

export const styles = {
  header: {
    minHeight: '100vh',
    // pt: '10vh',
    // width: '100vh',
    // maxHeight: '100%',
    // overflow: 'auto',

    width: '100%',
    // flexGrow: 1,
    // display: 'flex',
    // flexWrap: 'wrap',
    // flexDirection: 'row',
    // position: 'absolute',

    // backgroundSize: 'cover',
    // top: 0,
    paddingBottom: { xs: '0px', md: '50px' },
    // paddingTop: '10vh',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    // background: 'blue',
  },

  content: {
    // height: { md: '100vh' },
    // width: '100%',
    // flexGrow: 1,

    display: 'flex',
    // backgroundColor: 'rgba(0, 0, 0, 0.6)',
    // paddingTop: 5,
    // background: Color.RED,
  },
  innerContent: {
    // height: '100%',
    // width: '100%',
    backgroundColor: 'rgba(211,211,211, 0.1)',
    borderRadius: 6,
    // marginTop: '15%',
    p: '5%',
    border: '1px solid',
    borderColor: Color.BLACK_OP30,
    // flexDirection: 'column',
    height: { xs: '350px', md: '430px' },
    width: { xs: '66%', md: '300px' },
    minWidth: { xs: '220px', md: '350px' },
    boxShadow: '0 14px 28px rgba(0,0,0,0.66), 10px 10px 10px rgba(0,0,0,0.33)',
    // justifyContent: 'center',
    // alignItems: 'center',
    // position: 'relative',
    // top: 30,
    // left: 0,
    // right: 0,
    // bottom: -30,
  },
  galleryInnerContent: {
    // backgroundColor: 'rgba(211,211,211, 0)',
    backgroundColor: Color.DARK_GREY900,
    borderRadius: 6,
    // marginTop: '15%',
    p: '3%',
    marginTop: 5,
    border: '1px solid',
    borderColor: Color.BLACK_OP30,
    // backgroundColor: 'red',
    width: '95%',
    height: '80%',
    // flexDirection: 'column',
    // height: { xs: '350px', md: '430px' },
    // width: { xs: '95%', md: '95%' },
    // minWidth: { xs: '95%', md: '95%' },
    boxShadow: '0 14px 28px rgba(0,0,0,0.66), 10px 10px 10px rgba(0,0,0,0.33)',
  },
  contactHeaderLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    textDecoration: 'none',
    mb: 2,
  },
  contactIconStyling: {
    marginRight: 2,
    color: Color.WHITE,
    width: '100%',
    height: '50px',
    // margin: 'auto',
    // backgroundColor: 'yellow'
  },
  contactIconStylingCursor: {
    marginRight: 2,
    color: Color.WHITE,
    width: '100%',
    height: '50px',
    cursor: 'pointer',
  },
  workingDaysMainBox: { display: 'flex', pt: 1, pb: 1 },
  workingHoursMainBox: { display: 'flex', flexDirection: 'column' },
  priceWrapperBox: { mt: 1, width: '100%', flexDirection: 'row', justifyContent: 'center' },
  flex: { display: 'flex', width: '22%' },
  gapBoxXs: {
    mb: 2,
  },
  mainInnerContainer: {
    // flexGrow: 1,
    // display: 'flex',
    flexDirection: { xs: 'column', md: 'column' }, //related to XS
    // width: '100% ',
    // backgroundColor: 'black',
    paddingBottom: '50px',
    // height: '100%',
  },
  descriptionAboutPricesBox: {
    display: 'flex',
    // backgroundColor: 'blue',
    width: '100%',
    mt: 2,
    justifyContent: 'center',
  },
  descriptionAboutPricesInnerBoxWrapper: {
    minWidth: { xs: '220px', md: '350px' },
    width: { xs: '66%', md: '300px' },
    // background: 'red',
    maxWidth: '100%',
  },
  descriptionAboutUs: {
    // display: 'flex',
    // backgroundColor: 'blue',
    flexDirection: 'row',
    width: '100%',
  },
}

export const Contact = () => {
  const { t } = useTranslation()

  const navigateToGoogleMaps = (): void => {
    open('https://goo.gl/maps/Heq5EGVkGa9WibQh8', '_blank')
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={styles.content}>
        <Container sx={styles.mainInnerContainer}>
          <Container sx={styles.innerContent}>
            <Typography align={'center'} variant={'h4'} color={Color.WHITE} sx={styles.contactHeaderLine}>
              <AccessTimeFilledOutlinedIcon sx={styles.contactIconStyling} />
              {t('workingHours')}
            </Typography>
            <Box sx={styles.workingDaysMainBox}>
              <Box sx={styles.flex}>
                <Typography align={'left'} variant={'h5'} color={Color.WHITE}>
                  {`${t('II')}-${t('IV')}`}
                </Typography>
              </Box>
              <Box sx={styles.workingHoursMainBox}>
                <Typography align={'left'} variant={'h5'} color={Color.WHITE}>
                  {`${t('number16')}:${t('number00')} - ${t('number23')}:${t('number00')}`}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.workingDaysMainBox}>
              <Box sx={styles.flex}>
                <Typography align={'left'} variant={'h5'} color={Color.WHITE}>
                  {`${t('V')}-${t('VI')}`}
                </Typography>
              </Box>
              <Box sx={styles.workingHoursMainBox}>
                <Typography align={'left'} variant={'h5'} color={Color.WHITE}>
                  {`${t('number16')}:${t('number00')} - ${t('number02')}:${t('number00')}`}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.workingDaysMainBox}>
              <Box sx={styles.flex}>
                <Typography align={'left'} variant={'h5'} color={Color.WHITE}>
                  {`${t('VII')}`}
                </Typography>
              </Box>
              <Box sx={styles.workingHoursMainBox}>
                <Typography align={'left'} variant={'h5'} color={Color.WHITE}>
                  {`${t('number16')}:${t('number00')} - ${t('number23')}:${t('number00')}`}
                </Typography>
              </Box>
            </Box>
          </Container>
          <Box sx={{ mb: 5 }} />
          <Container sx={styles.innerContent}>
            <Link underline={'none'} href={`tel:${t('phoneNumber')}`}>
              <LocalPhoneOutlinedIcon sx={styles.contactIconStyling} />
              <Typography
                align={'center'}
                variant={'h5'}
                color={Color.WHITE}
                sx={[styles.contactHeaderLine, { mb: 0 }]}
              >
                {t('phoneNumber')}
              </Typography>
            </Link>
            <Box sx={styles.gapBoxXs} />
            <LocationOnOutlinedIcon onClick={navigateToGoogleMaps} sx={styles.contactIconStylingCursor} />
            <Typography
              onClick={navigateToGoogleMaps}
              align={'center'}
              variant={'h5'}
              color={Color.WHITE}
              sx={[styles.contactHeaderLine, { mb: 0, cursor: 'pointer' }]}
            >
              {t('addressLocation')}
            </Typography>

            <Box sx={styles.gapBoxXs} />
            <Link underline={'none'} href={`mailto:${t('cyberPoolInfoEmail')}`}>
              <MailOutlinedIcon sx={styles.contactIconStyling} />
              <Typography align={'left'} variant={'h5'} color={Color.WHITE} sx={styles.contactHeaderLine}>
                {t('cyberPoolInfoEmail')}
              </Typography>
            </Link>
          </Container>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Contact
