export default {
  test: 'testas',
  homeScreenTitle: 'Home',
  aboutUsScreenTitle: 'About us',
  galleryScreenTitle: 'Gallery',
  pricesScreenTitle: 'Prices',
  contactsScreenTitle: 'Contacts',
  schoolsScreenTitle: 'School',
  eventsScreenTitle: 'Events',
  workingHours: 'Working hours',
  I: 'I',
  IV: 'IV',
  V: 'V',
  VI: 'VI',
  VII: 'VII',
  number12: '12',
  number14: '14',
  number16: '16',
  number15: '15',
  number17: '17',
  number20: '20',
  number23: '23',
  number02: '02',
  number00: '00',
  number40: '40',
  number50: '50',
  number45: '45',
  number18: '18',
  addressLocation: 'Antakalnio g. 87, LT-10215, Vilnius',
  phoneNumber: '+37064810967',
  cyberPoolInfoEmail: 'info@cyberpool.lt',
  playPrices: 'Game prices',
  tableCostPerHour: 'Table price per hour',
  tableLPFDiscount:
    'LFP pool athletes based on 2022m. LFP pool men, women, veterans with 45+ ratings get 40% discount.',
  tableCostDiscountForDisabled: '50% discount for disabled',
  tableExtraCostForICA: 'ICA training system +5€ additional pay for table rent',
  tableSoldierDiscountTitle: '40% discount for soldiers',
  tableCostDiscountForStudents: '20% discount for students',
  eur: '€',
  note: '*',
  aboutUsCyberIntroInfo:
    'CYBER POOL first interactive Pool club in Baltic Countries located in Vilnius (Antakalnio 87, Vilnius)',
  aboutUsCyberDesignInfo:
    'Exceptional design and atmosphere invite you not only for entertainment but also to practice your skills',
  aboutUsCyberWhatWeDoHeader: 'So what do we do exactly?',
  aboutUsCyberWhatWeDoDescription:
    'Pool is a classification of cue sports played on a table with six pockets along the rails, into which balls are deposited. Each specific pool game has its own name; some of the better-known include eight-ball, blackball, nine-ball, ten-ball, seven-ball, straight pool, one-pocket, and bank pool.',
  aboutUsCyberWhatWeDoDescription2:
    'We wanted to make practicing the cueing arts fun and efficient. Setting up exercises and drills is too time consuming which makes staying motivated difficult. Our ICA Training system solves both these problems. Setting up layouts is quick and repeatable, letting you practice til you "get it right"! Adding structure and efficiency to your training regimen is simple with the complete set of tools included in this training system. Also is great and neat place for relaxation and fun or simple to spend some time with friends',
  schoolsBeginningHeader: 'Pool school beginning 2022',
  schoolsInnerHeader: 'Together we reach our goals! 💯',
  schoolsTrainingWithICA: "Let's train with interactive ICA training system",
  schoolsInsideMatches: 'Internal tournaments',
  schoolsCertificatesAreGiven: 'On completion certificates are given',
  schoolsInviteToGroupPoolTraining: '👋 Your welcome to group training sessions',
  schoolsWhenGroupPoolTrainingTakePlaceTitle: 'When is it taking place?',
  schoolsWhenGroupPoolTrainingTakePlaceDescription: 'Tuesday, Thursday 15.00 - 17.00',
  schoolsWhereGroupPoolTrainingTakePlaceTitle: 'Where?',
  schoolsWhereGroupPoolTrainingTakePlaceDescription: 'Antakalnio 87, Vilnius',
  schoolsCyberPoolClub: 'CYBER POOL pool club',
  schoolsCyberPoolTrainer: 'Vilius Schulte-Ebbert snucker/pool player and certified EBSA coach',
  schoolsAboutCyberPoolTrainer: 'Coach with 8 years experience from 2014-2022. ⬇️',
  schoolsAboutCyberPoolTrainer1: '♦️ 900 + students from 46 countries from 7 years to 75. Also working with disabled.',
  schoolsAboutCyberPoolTrainer2: '♦️ 3 online snucker pool courses are available.',
  schoolsAboutCyberPoolTrainer3: '♦️ Experienced working with snucker, pool, russian pool players.',
  schoolsRegisterForTraining: 'Where to register for training?',
  schoolsTrainerEmail: 'Schulte.ebbert@gmail.com',
  schoolsTrainerOr: 'or',
  schoolsTrainerPhone: '+37062089254',
  schoolsTrainerFullName: ' (trainer Vilius Schulte-Ebbert)',
  schoolsSeeYouSoon: 'See you soon!',
  username: 'Username',
  password: 'Password',
  login: 'Login',
  eventStart: 'Start date:',
  eventEnd: 'End date:',
  eventLocation: 'Location:',
  news: 'Events',
  title: 'Title',
  description: 'Description',
  imageStyle: 'Image Style',
  imageContainerStyle: 'Image container style',
  imagePosition: 'Image position',
  imgPositionBottom: 'Bottom',
  imgPositionTop: 'Top',
  imgPositionLeft: 'Left',
  imgPositionRight: 'Right',
  type: 'Type',
  typeChallenge: 'Challenge',
  typeNews: 'News',
  typeInfo: 'Information',
  typeEvent: 'Event',
  submit: 'Submit',
  englishLanguageSwitch: 'English language',
  remove: 'Remove',
  descriptionLink: 'Link',
  readMore: 'Read more',
  readLess: 'Read less',
}
